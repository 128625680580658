<template>
  <v-container id="case-index-container" class="fill-height" fluid>
    <repository-header :title="$t('testCases')" :actionText="$t('createTestCase')"></repository-header>
    <v-row align-content="start" justify="start" dense class="fill-height" v-if="!isTreeViewCollapsed">
      <!-- Tree View -->
      <v-col class="fill-height mt-2" cols="3" sm="3">
        <TreeView coallsed="{isTreeViewCollapsed}"/>
        <div class="position-relative">
          <div class="collapse-btn" @click="toggleMenu">
            <v-icon>mdi-chevron-left</v-icon>
            <span v-if="!isTreeViewCollapsed" class="collapse-text">{{ $t('collapse') }}</span>
          </div>
        </div>
      </v-col>
      <!-- Cases List -->
      <v-col class="fill-height mt-2 position-relative" cols="9" sm="9" v-if="isDetailViewCollapsed">
        <cases-list id="cases-list" isRepository="true" @expandDetail="changeExpansion"></cases-list>
      </v-col>
      <v-col cols="9" sm="9" class="fill-height mt-2 position-relative" v-else>
        <v-row class="fill-height">
          <v-col cols="6" sm="6" class="pr-1 card">
            <cases-list id="cases-list" isRepository="true" @expandDetail="changeExpansion"></cases-list>
          </v-col>
          <v-col cols="6" sm="6" class="pl-1">
            <detail-view @closeDetail="closeDetailView"></detail-view>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row align-content="start" justify="start" dense class="fill-height" v-else>
      <!-- Tree View -->
      <v-col class="fill-height mt-2" cols="1" sm="1">
        <TreeView :collapsed="{isTreeViewCollapsed}"/>
        <div class="position-relative">
          <div class="collapse-btn" @click="toggleMenu">
            <v-icon>mdi-chevron-right</v-icon>
          </div>
        </div>
      </v-col>
      <!-- Cases List -->
      <v-col class="fill-height mt-2 position-relative" cols="11" sm="11" v-if="isDetailViewCollapsed">
        <cases-list id="cases-list" isRepository="true" @expandDetail="changeExpansion"></cases-list>
      </v-col>
      <v-col cols="11" sm="11" class="fill-height mt-2 position-relative" v-else>
        <v-row class="fill-height">
          <v-col cols="6" sm="6" class="pr-1">
            <cases-list id="cases-list" isRepository="true" @expandDetail="changeExpansion"></cases-list>
          </v-col>
          <v-col cols="6" sm="6" class="pl-1">
            <detail-view></detail-view>
          </v-col>
        </v-row>

      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import CasesList from '@/views/Tests/Case/List/Index.vue';
import TreeView from '@/views/Tests/Case/Tree/Index.vue';
import DetailView from '@/views/Tests/Case/Detail/Index.vue';
import RepositoryHeader from '@/components/TestCases/RepositoryHeader.vue';


export default {
  name: 'CaseFromRepository',
  components: {
    RepositoryHeader,
    CasesList,
    TreeView,
    DetailView,
  },
  data() {
    return {
      isTreeViewCollapsed: false,
      isDetailViewCollapsed: true,
    }
  },
  methods: {
    toggleMenu() {
      this.isTreeViewCollapsed = !this.isTreeViewCollapsed
    },
    changeExpansion(item) {
      this.isDetailViewCollapsed = false;
    },
    closeDetailView() {
      this.isDetailViewCollapsed = true;
    }
  },
}

</script>

<style>
.position-relative{
  position: relative !important;
}
</style>
